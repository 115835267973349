.modal {
    font-size: 18px;
  }

.modal > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 26px;
    text-align: center;
    padding: 5px;
  }

.modal > .content {
    width: 90%;
    padding: 10px 5px;
    text-align: justify;
  }

.modal > .actions {
    width: 100%;
    padding: 10px 5px;
  }

.modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
  }

  