nav {
    display: flex;
    justify-content: center;
    width: 90%
}

#navigation-bg {
    /* background: linear-gradient(to right, #eef2f3 0%, #8592be 30%, #8592be 70%, #eef2f3 100%); */
    width: 100%;
    background: #a8c0ff
}