#list {
	/* display: inline-block; */
	flex-wrap: wrap;
	margin-left: auto;
	margin-right: auto;
	height: auto;
	width: 75%;
}

#list div p, .container p {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	font-size: 2rem;
}

#compatibilityListTitle {
	font-style: italic;
}